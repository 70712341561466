<template>
  <div>
    <section class="container-fluid p-0" style="background-color: #fff">
    <div class="col-12">
        <div class="row">
            <div class="col-12 col-md-12 col-lg-6 bg-login-2 d-flex" :style="{'background-image': `url(${required('bg-login-qsuite.jpg')})`}">
                <div class="align-self-end p-bg-login w-100 text-center text-lg-left">
                    <div class="d-lg-none d-block pb-2">
                        <img src="@/assets/img/logo-gsuitew.png" alt="Qsuite" style="max-height: 35px;">
                    </div>
                    <h1 class="text-white title-maisconexoes">
                        <b>Mais conexões de um só lugar!</b>
                    </h1>
                </div>
            </div>
            <div class="col-12 col-md-12 col-lg-6 my-auto">
                <div class="col-12 text-center text-sm-right p-logo-login d-none d-lg-block justify-content-center">
                    <img src="@/assets/img/logo-gsuite.png" alt="Qsuite" style="max-height: 40px;">
                </div>
                <div class="col-12 text-center p-entrar-login">
                </div>
                <div class="col-12 text-center">
                </div>
                <div class="col-12 col-md-10 col-lg-8 mx-auto pb-4">
                    <form @submit.prevent="login" novalidate>
                      <div class="col-12">
                          <label class="d-none">E-mail </label>
                          <input type="email" v-model="email" class="form-control inputs-login" placeholder="E-mail" name="email" v-validate="'required|email'">
                          <b-form-invalid-feedback :state="!errors.has('email')">
                            Por favor! Informe o seu e-mail.
                          </b-form-invalid-feedback>
                      </div>
                      <div class="col-12 pt-2">
                          <label class="d-none">Senha </label>
                          <input type="password" class="form-control inputs-login" id="passtext" placeholder="********" v-model="password" name="password" v-validate="'required'">
                          <b-form-invalid-feedback :state="!errors.has('password')">
                            Por favor! Informe a senha.
                          </b-form-invalid-feedback>
                      </div>
                      <b-form-invalid-feedback :state="!error">
                           Email ou senha incorretos.
                        </b-form-invalid-feedback>
                      <div class="col-12 text-right pt-2">
                          <b-link :to="{name: 'AdminRecovery'}" title="Senha" class="font-12 a-link-1">Esqueci minha senha?</b-link>
                      </div>
                      <div class="col-12 text-center pt-3">
                            <button type="submit" class="btn btn-info button-login-admin">Entrar</button>
                      </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
<BaseLoginFooter />
    
  </div>
</template>
<script>
import Cookies from 'js-cookie';

export default {
  data() {
    return {
      email: null,
      password: null,
      error: false
    };
  },
  methods: {
    required(image){
      if(image.includes('https')){
        return image;
      }

      return require(`@/assets/img/${image}`); 
    },
    login(){
      this.$validator.validateAll().then((result) => {
        if (result) {
          
          let data = {
            username: this.email,
            password: this.password,
            role_id: 4 // ADMIN,
          };

          const company_id = Cookies.get('company_id');

          if(company_id) {
            data['company_id'] = company_id;
          }

          this.$store
            .dispatch("loginRequest", data)
            .then(() => {
            })
            .catch((err) => {
              console.log(err);
              this.error = true;
            });
        }
      });
    },

  },
};
</script>
<style scoped lang="scss">
</style>